import React, { useState } from "react";
import "./App.css";
import crypto from "crypto-js";

function App() {
  const [names, setNames] = useState("");
  const [randomName, setRandomName] = useState("");

  const handleInputChange = (e) => {
    setNames(e.target.value);
  };

  const handleSubmit = () => {
    const nameList = names.split("\n").filter((name) => name.trim() !== "");
    const index = getRandomIndex(nameList.length);
    setRandomName(nameList[index]);
  };

  const getRandomIndex = (length) => {
    const randomBytes = crypto.lib.WordArray.random(4);
    const randomInt = parseInt(randomBytes.toString(crypto.enc.Hex), 16);
    return randomInt % length;
  };

  return (
    <div className="App">
      <h1>Random Name Picker</h1>
      <textarea
        rows="10"
        cols="30"
        placeholder="Enter names, one per line"
        value={names}
        onChange={handleInputChange}
      ></textarea>
      <br />
      <button onClick={handleSubmit}>Submit</button>
      {randomName &&
        <div>
          <h2>Randomly Selected Name:</h2>
          <p>{randomName}</p>
        </div>
      }
      
      <p><img src="/hat.png" alt="A Hat Full of Names" /></p>
    </div>
  );
}

export default App;